import React from 'react'
import Link from 'gatsby-link'

import logo2019_sm from '../2019/media/logo_devday_2019_2.svg'
import logo2019_lg from '../2019/media/logo_devday_2019_1.svg'

import logo2020_sm from '../2020/media/logos/light.svg'
import logo2020_lg from '../2020/media/logos/light.svg'

const logos = {
    "2019": {
        sm: logo2019_sm,
        lg: logo2019_lg
    },
    "2020": {
        sm: logo2020_sm,
        lg: logo2020_lg
    }
}

export default ({ year }) => (
    <div className="site-header__branding">
        <div className="site-branding ">
            <Link to="/">
                <img src={logos[year].sm} alt="" className="d-lg-none" />
                <img src={logos[year].lg} alt="" className="d-none d-lg-block" />
            </Link>
        </div>
    </div>
)
