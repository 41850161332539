import React from 'react'

import {
  faTwitter,
  faLinkedin,
  faGithub,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default ({ speaker, iconSize }) => (
  <>
    {speaker.twitterHandle && (
      
        <a href={`//twitter.com/${speaker.twitterHandle}`} target="_blank" style={{padding:".5em"}}>
          <FontAwesomeIcon icon={faTwitter} size={iconSize} />
        </a>
      
    )}
    {speaker.githubHandle && (
      
        <a href={`//github.com/${speaker.githubHandle}`} target="_blank" style={{padding:".5em"}}>
          <FontAwesomeIcon icon={faGithub} size={iconSize} />
        </a>
      
    )}
    {speaker.linkedInProfile && (
      
        <a href={speaker.linkedInProfile} target="_blank" style={{padding:".5em"}}>
          <FontAwesomeIcon icon={faLinkedin} size={iconSize} />
        </a>
      
    )}
  </>
)
