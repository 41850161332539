import React from 'react'
import { push as Menu } from 'react-burger-menu'
import { Link } from 'gatsby'

export default props => (
  <Menu
    onStateChange={props.setShowMenu}
    isOpen={props.showMenu}
    customBurgerIcon={false}
    pageWrapId={'page-wrap'}
    outerContainerId={'outer-container'}
  >
    <div className="site-sidenav__nav">
      <div className="site-sidenav__menu">
        <nav
          className="sidenav-navigation"
          aria-label="Site Mobile Menu"
          role="navigation"
        >
          <div className="menu-sidenav-menu-container">{props.children}</div>
        </nav>
      </div>
    </div>
  </Menu>
)
