import React from 'react'

import { Container, Row, Col } from 'reactstrap'

function Section(props) {

  return <section className={`${props.section}`} style={props.style}>
    {
      props.bg && (
        <figure
          className="reveal__background  bg-image"
          style={{ backgroundImage: `url(${props.bg})` }}
        />
      )
    }
    < Container >
      <Row className={props.row}>
        <Col xl={props.xlSize} className={props.col}>
          {props.children}
        </Col>
      </Row>
    </Container >
  </section >
}


Section.defaultProps = {
  section: "section",
  style: {},
  bg: null,
  row: "",
  col: "",
  xlSize: { size: 10, offset: 1 },
  children: {}
}

export default Section;