
const jsonSchema = {
    "@context": "http://schema.org",
    "@type": "Event",
    "name": "DEVDAY'20",
    "url": "https://devday.io/",
    "description": "DEVDAY'20 is a deep tech festival for developers and tech enthusiasts. Save the dates on 2020/05/22-23 to join us in Faro/Portugal for talks, workshops and discussions",
    "startDate": "2020-05-22T09:00+00:00",
    "endDate": "2020-05-23T20:00+00:00",
    "image": "https://devday.io/img/devopengraph2020.jpg",
    "isAccessibleForFree": true,
    "location": {
        "@type": "Place",
        "name": "Associação Recreativa E Cultural De Músicos",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "R. do Castelo 2",
            "addressLocality": "Faro",
            "addressCountry": "Portugal"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": 37.0115849,
            "longitude": -7.9338671
        }
    },
    "offers": {
        "@type": "Offer",
        "price": "0",
        "priceCurrency": "USD"
    },
    "organizer": {
        "@type": "Organization",
        "name": "Turbine Kreuzgberg PT",
        "url": "https://turbinekreuzberg.com"
    }
}

const jsonSchemaString = JSON.stringify(jsonSchema)
export default jsonSchemaString