import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Nav = props => (
  <div className="widget">
    <h2 className="widget-title">{props.title}</h2>
    <div className="widget_nav_menu">{props.children}</div>
  </div>
)
const Network = props => (
  <a className="widget_socials__item" href={props.href} target="_blank">
    <div className="sr-only">Follow us</div>
    <FontAwesomeIcon icon={props.icon} />
  </a>
)

const Social = props => (
  <div className="widget">
    <div className="widget_socials">
      <div className="site-branding">{props.branding}</div>
      <div className="widget_socials__list">{props.children}</div>
    </div>
  </div>
)

const Branding = props => (
  <a href={props.href} className={props.className}>
    <img src={props.img} alt="" />
  </a>
)

export { Nav, Social, Branding, Network }
