import { default as React, useState } from 'react'
import { Flip } from 'react-reveal'
import { Col, Row } from 'reactstrap'
import Wildcard from '../common/Wildcard'
import SpeakerSocialElements from '../common/Talks/SpeakerSocialElements'
import bg4 from './media/bg4.svg'
import Img from 'gatsby-image'
import RichText from '../common/RichText'

export const Tab = ({active = false, select, name, children}) => {
    return <a 
        className={`tabs__nav ${active?"tabs__nav--active":""}`} 
        onClick={(e) => {e.preventDefault(); select(name); return false;} } 
        tabIndex="0" 
        role="tab" 
        style={{textDecoration: "none"}}
        aria-selected={active}>{children}
      </a>
    }
    
export const SpeakerImage = ({image}) => (
    <figure>{image && <Img
    style={{borderRadius: '100%'}}
    durationFadeIn={250}
        fluid={image.localFile.childImageSharp.fluid}
        alt={image.title} />
    }
    </figure>
)
    
export const TabbedSpeakerTalkBox = ({speaker, talk}) => {
    
    const [tab, setTab] = useState("bio")
    
    return (
    <div>
        <div className="tabs__navs">
            <Tab name="bio" select={setTab} active={tab=="bio"}>Bio</Tab>
            <Tab name="talk" select={setTab} active={tab=="talk"}>Talk</Tab>
            <span ><SpeakerSocialElements speaker={speaker} iconSize="lg" /></span>
        </div>
        
        <div style={{position:"relative"}} >
            <Flip  top opposite collapse when={tab=="bio"} style={{position: "absolute", top: 0}} >
            <div style={{position: "absolute", top: 0}}>
            {speaker.bio && 
                <div className="margin-top" dangerouslySetInnerHTML={{__html: speaker.bio.childMarkdownRemark.rawMarkdownBody}}></div>
                }
            </div>
            </Flip>
            <Flip  top opposite collapse when={tab=="talk"} style={{position: "absolute", top: 0}}>
            <div style={{position: "absolute", top: 0}}>
                <h4 style={{fontWeight: 900}}>{talk.title}</h4>
                <div dangerouslySetInnerHTML={{__html: talk.abstract.childMarkdownRemark.rawMarkdownBody}}></div>
            </div>
            </Flip>
        </div>
    </div>
    )
}

const SpeakerWildCard = (props) => (
<Wildcard 
    {...props}
        backgroundClass="bg-image-lt"
        backgroundStyle={{ backgroundImage: `url(${bg4})` }}
        section="section"
        >
    {props.children}
</Wildcard>
)

export const Talk = ({talk, dir}) => {
    const speaker=talk.speaker[0]
    return <SpeakerWildCard align={dir} revealClasses="reveal--darken" color="tertiary">

        <Row>
            <Col md="4" className={`order-md-${dir=='left'?2:1}` }>
                <SpeakerImage image={speaker.image} />
            </Col>
            <Col md="8" className={`order-md-${dir=='left'?1:2}` }>
                <h3 className="margin-top-0 display-two" >{speaker.name}</h3>
                <p className="dashed">
                    {speaker.position} {speaker.company && (<span>| <b>  {speaker.company}</b></span>)}
                </p>
                <TabbedSpeakerTalkBox speaker={speaker} talk={talk} />
            </Col>
        </Row>                
    </SpeakerWildCard>
}

export const Workshop = ({workshop, dir}) => {
    
    return <SpeakerWildCard align={dir} color="white">
            <h2>{workshop.title}</h2>
            <Row>
                <Col md="4" className={`order-md-${dir=='left'?2:1}` }>
                <figure>{workshop.companyLogo && <img
                        src={workshop.companyLogo.file.url}
                        alt={workshop.companyLogo.title} />
                    }
                </figure>
                </Col>
                <Col md="8" className={`order-md-${dir=='left'?1:2}` }>
                    {workshop.speaker.map( speaker => (
                        <Row key={speaker.id}>
                            <Col md="2" style={{margin: "auto"}}>
                                <SpeakerImage image={speaker.image} />
                            </Col>
                            <Col>
                            <h4 >{speaker.name}</h4>
                            <p>
                                {speaker.position} {speaker.company && (<span>| <b>  {speaker.company}</b></span>)}
                            </p>
                            </Col>
                        </Row>
                    ))}
                </Col>
            </Row>
            <Row className="margin-top">
                <Col>
                    <RichText json={workshop.description.json} />
                </Col>
            </Row>                
        </SpeakerWildCard>
  }
    