import { graphql, useStaticQuery, Link } from 'gatsby'

import { default as React, useState } from 'react'


import Layout from '../components/common/Layout'
import Section from '../components/common/Section'
import CtaBlock from '../components/common/CtaBlock'

import keyvisual from '../components/2020/media/bg4.svg'
import { Talk } from '../components/2020/Contributions'

export default () => {
  const data = useStaticQuery(graphql`
  {
    talks: allContentfulTalk(
      filter: { year: { eq: 2020 }, node_locale: { eq: "de" } }
      sort: { fields: [speaker___name], order: ASC }
    ) {
      edges {
        node {
          id
          node_locale
          title
          slotTime
          location
          youtubeUrl
          slides
          winner
          highlights {
            highlights
          }
          statement {
            statement
          }
          abstract {
            childMarkdownRemark {
              html
            }
          }
          speaker {
            id
            name
            position
            company
            twitterHandle
            githubHandle
            linkedInProfile
            claim
            image {
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            bio {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
  `)

  const talks = data.talks.edges.map(({ node }) => node)

  return (
    <Layout>
      <Section xlSize={{ size: 12 }} section="section section--first" bg={keyvisual}>
        <h1 className="page-title display-one">Talks</h1>
        <h2 className="page-subtitle outline display-two">
          Meet your speakers
          </h2>

        {talks.map((talk, idx) => {
          const dir = idx % 2 == 0 ? 'left' : 'right'
          return <Talk talk={talk} dir={dir} key={`talk-${idx}`} />
        })}
      </Section>

      <CtaBlock backgroundColor="primary" className="margin-bottom">
        <Link to="/call-for-speakers" className="btn btn--tertiary">
          <span className="big">
            Give a talk at
            DEVDAY
            </span>
        </Link>
      </CtaBlock>
    </Layout>
  )
}