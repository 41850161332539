import React from 'react'
import { Link } from 'gatsby'

export default props => (
  <ul className="menu">
    <li className="d-lg-none d-xl-none">
      <Link to="/" activeClassName="active">
        Home
      </Link>
    </li>


    <li>
      <Link to="/call-for-speakers" activeClassName="active">
        Call for Speakers
      </Link>
    </li>



    <li>
      <Link to="/team" activeClassName="active">
        Team
      </Link>
    </li>

    <li className="menu-item-has-children">
      <a href="#">Recaps</a>
      <ul className="sub-menu">
        <li>
          <Link to="/2019">
            <span>2019</span>
          </Link>
          <Link to="/2018">
            <span>2018</span>
          </Link>
        </li>
      </ul>
    </li>

    <li className="menu-item-has-children">
      <a href="#">Legal</a>
      <ul className="sub-menu">
        <li>
          <Link to="/imprint">
            <span>Imprint</span>
          </Link>
          <Link to="/privacy-policy">
            <span>Privacy policy</span>
          </Link>
          <Link to="/code-of-conduct">
            <span>Code of Conduct</span>
          </Link>
        </li>
      </ul>
    </li>

  </ul>
)
