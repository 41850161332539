import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Headroom from 'react-headroom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Branding from './Branding'

import Menu from './Menu'

const Burger = props => (
  <div className="site-header__mobile hidden-lg-up">
    <button
      className="site-header__mobile--trigger side-menu-trigger"
      onClick={props.toggleMenu}
    >
      <FontAwesomeIcon icon={faBars} />
    </button>
  </div>
)

export default (props) => (
  <header className="site-header headroom headroom--not-bottom headroom--pinned headroom--top">
    <Headroom disableInlineStyles={true}>
      <header className="site-header" id="masthead">
        <div className="site-header__wrap">
          <Container>
            <Row>
              <Col>
                <div className="site-header__elements">
                  <Burger toggleMenu={props.toggleMenu} />
                  <Branding year={props.year || "2020"} />
                  <div className="site-header__nav hidden-md-down">
                    <div className="site-header__menu">
                      <nav
                        className="main-navigation"
                        aria-label="Site Menu"
                        role="navigation"
                      >
                        <div className="menu-top-menu-container">
                          <Menu id="top-menu" />
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </Headroom>
  </header>
)

{
  /*
<div className="site-header__cta hidden-sm-down">
                        <Link
                          className="btn btn--secondary"
                          activeStyle={{ textDecoration: 'underline' }}
                          to="/call-for-speakers"
                        >
                          Call for Speakers
                        </Link>
                      </div>
                      */
}
