import React from 'react'
import { Tada } from 'react-reveal'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'

export default class CtaBlock extends React.Component {
  render() {
    return (
      <div
        className={`cta cta--darken cta--${this.props.backgroundColor}`}
        data-offset="90%"
      >
        <div className="cta__copy text-center">
          <div className="d-inline-block margin-top margin-bottom">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

CtaBlock.defaultProps = {
  backgroundColor: 'secondary',
  color: 'primary',
}
