import React from 'react'
import { Container, Row, Col } from 'reactstrap'

export default props => (
  <div className="site-info">
    <Container>
      <Row>
        <Col>
          <p className="sub-head">
            made with burgers and beer, bootstrap, gatsby.js &amp; react.js.
            headless content by contentful &amp; cloudinary. hosted &amp;
            continuously deployed on gitlab &amp; netlify. Theme &copy;
            mae.pixudio.com. uses slick slider, headroom, responsive embed
          </p>
        </Col>
      </Row>
    </Container>
  </div>
)
