import React from 'react'
import logosvg from '../../2020/media/logos/full.svg'
import logotxb from '../media/logo_txb_black.png'
import { Container, Row, Col } from 'reactstrap'
import Link from 'gatsby-link'
import Subfooter from './Subfooter'
import * as Widget from './Widget'
import {
  faTwitter,
  faFacebook,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import {
  faEnvelope,
  faPhone,
  faHashtag,
  faAt
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class SiteFooter extends React.Component {
  render() {
    return (
      <footer className="site-footer" id="colophon" role="contentinfo">
        <div className="widget-area" role="complementary">
          <Container>
            <Row>
              <Col md={6} lg={{ size: 3, offset: 1 }}>
                <Widget.Nav title="" />
                <Widget.Social
                  branding={
                    <>
                      <Widget.Branding img={logosvg} href="/" />
                      <Widget.Branding
                        className="margin-top"
                        img={logotxb}
                        href="https://turbinekreuzberg.com"
                      />
                    </>
                  }
                />
              </Col>
              <Col md={6} lg={3}>
                <Widget.Nav title="Contact">
                  <ul>
                    <li>

                      <a href="mailto:devday@turbinekreuzberg.com" style={{ whiteSpace: "nowrap" }}>
                        <FontAwesomeIcon icon={faEnvelope} />{' '} devday@turbinekreuzberg.com
                      </a>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faPhone} /> +49 30 2847 2640 - 0
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faAt} />
                      <a href="https://twitter.com/DevDayTK"> DevDayTK</a>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faHashtag} /> devday21
                    </li>
                  </ul>
                </Widget.Nav>
              </Col>

              <Col md={6} lg={2}>
                <Widget.Nav title="Social" />
                <Row>
                  <Col xs={9}>
                    <Widget.Network
                      icon={faTwitter}
                      href="https://twitter.com/DevDayTK"
                    />
                    <Widget.Network
                      icon={faFacebook}
                      href="https://www.facebook.com/turbinekreuzbergPT/"
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={6} lg={3}>
                <Widget.Nav title="Legal Terms">
                  <ul>
                    <li>
                      <Link to="/imprint">Imprint</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/code-of-conduct">Code of Conduct</Link>
                    </li>
                  </ul>
                </Widget.Nav>
              </Col>
            </Row>
          </Container>
        </div>

        <Subfooter />
      </footer>
    )
  }
}
