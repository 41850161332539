import React from 'react'
import { graphql, Link } from 'gatsby'
import { Fade } from 'react-reveal'

import Section from './Section'

export default props => (
  <Section {...props}>
    <div
      className={`reveal reveal--${
        props.align
        } reveal--${props.color} ${props.revealClasses}`}
    >
      <figure
        className={`reveal__background  ${props.backgroundClass}`}
        style={props.backgroundStyle}
      />
      <Fade bottom>
        <h3 className="">{props.title}</h3>
      </Fade>
      <Fade bottom cascade>
        <div className="reveal__copy ">
          {props.content || props.children}
        </div>

      </Fade>
      {props.cta}
    </div>
  </Section>
)
