import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Header from './Header'
import SiteFooter from './Footer/SiteFooter'
import favicon from './media/favicons/favicon.ico'
import favicon16 from './media/favicons/favicon-16x16.png'
import favicon32 from './media/favicons/favicon-32x32.png'

import SideMenu from './SideMenu'
import Menu from './Menu'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faComments, faUtensils, faCouch, faTrophy, faHandshake } from '@fortawesome/free-solid-svg-icons'
import jsonLdText from '../../data/json-schema.js'

library.add(faComments, faUtensils, faCouch, faTrophy, faHandshake)

import revealconfig from 'react-reveal/globals'
revealconfig({ ssrFadeout: true })

import '../2020/scss/main.scss'

function Layout(props) {

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = evt => {
    setShowMenu(!showMenu)
    evt.preventDefault()
  }

  const year = props.year || "2020"

  return (
    <StaticQuery
      query={graphql`
          query HeadingQuery2020 {
            meta: site {
              siteMetadata {
                title
                description
                ogdescription
                keywords
              }
            }
          }
        `}
      render={data => (
        <div className={props.className || ''}>
          <Helmet
            title={data.meta.siteMetadata.title}
            bodyAttributes={{ class: `home page site-${year}` }}
          >
            <meta name="description" content={data.meta.siteMetadata.description} />
            <meta name="keywords" content={data.meta.siteMetadata.keywords} />
            <meta
              property="og:title"
              content={data.meta.siteMetadata.title}
            />
            <meta property="og:type" content="Website" />
            <meta property="og:url" content="https://devday.io" />
            <meta
              property="og:description"
              content={data.meta.siteMetadata.ogdescription}
            />
            <meta
              property="og:image"
              content="https://devday.io/img/devopengraph2021.jpg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@DevDayTK" />
            <meta name="twitter:title" content={data.meta.siteMetadata.title} />
            <meta name="twitter:description" content={data.meta.siteMetadata.ogdescription} />
            <meta
              name="twitter:image"
              content="https://devday.io/img/devopengraph2021.jpg"
            />

            <meta property="fb:app_id" content="147601672114776" />
            <link rel="dns-prefetch" href="//fonts.googleapis.com" />
            {/* <link rel="dns-prefetch" href="//s.w.org" /> */}
            <link rel="icon" href={favicon} type="image/x-icon" />
            <link
              rel="icon"
              type="image/png"
              href={favicon32}
              sizes="32x32"
            />
            <link
              rel="icon"
              type="image/png"
              href={favicon16}
              sizes="16x16"
            />
            <link
              href="https://fonts.googleapis.com/css?family=Karla:400,700,700i|Work+Sans:700"
              rel="stylesheet"
            />
            <script src="https://www.eventbrite.de/static/widgets/eb_widgets.js"></script>
            <script type="application/ld+json">{jsonLdText}</script>
          </Helmet>

          <div className="site" id="page">
            <div id="outer-container">
              <Header toggleMenu={toggleMenu} year={year} />
              <SideMenu
                setShowMenu={(newState) => setShowMenu(newState.isOpen)}
                showMenu={showMenu}
              >
                <Menu />
              </SideMenu>

              <div className="site-content" id="page-wrap">
                <div className="content-area" id="primary">
                  <main className="site-main" id="main">
                    {props.children}
                  </main>
                </div>
              </div>
              <SiteFooter />
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default Layout;